import Swal from 'sweetalert2';

$(document).ready(function(){
  $("#subscriptionUpgradeForm").on("submit", function(){
    $(".customer-page-spinner").show();
  });

  App.showManualSubscriptionUpdateSuccessModal = function() {
    $("#changeSubscriptionDetails").modal("hide");
    $(".customer-page-spinner").hide();
    Swal.fire(
      'Success!',
      'Invoice has been successfully sent',
      'success'
    ).then(function () {
      $(".customer-page-spinner").show();
      location.reload()
    });
  }

  App.showManualSubscriptionUpdateFailedModal = function() {
    $(".customer-page-spinner").hide();
    Swal.fire(
      'Failed!',
      'Please try again later',
      'error'
    );
  }

  $(document).on("click",".btnInvoicePreview", function(){
    var form = $("#createInvoiceForm");
    var formObject =form[0];
    var isValid = formObject.reportValidity();
    if(isValid){
      $("#createInvoice").modal("hide");
      $("#invoicePreviewModal").modal("show");
      setTimeout(function(){
        $("body").addClass("modal-open");
        showInvoicePreview();
      },500);
    }
  });

  $(document).on('hide.bs.modal', '#invoicePreviewModal', function(){
    $("#createInvoice").modal("show");
    setTimeout(function(){
      $("body").addClass("modal-open");
    },500);
  });

  function showInvoicePreview() {
    var recurringType = $("input[name='recurring_type']:checked").val();
    var selectedPaymentGateway = $('.payment_gateway').val();
    var selectedInvoiceCurrency = $("#invoice_currency_prefix").val();
    if (!selectedInvoiceCurrency) {
      selectedInvoiceCurrency = "$";
    }
    if (selectedInvoiceCurrency != "$") {
      selectedInvoiceCurrency += " ";
    }
    var stripePaymentGateway = ["1", "4"].includes(selectedPaymentGateway);
    var paymentGateways = {
      "1": "Stripe", "2": "2Checkout", "3": "2Checkout Singapore", "4": "Stripe UAE - USD", "5": "Stripe UAE - AED", "6": "Magnati - AED"
    }
    var gatewayReferenceText = 'Gateway Order ID'
    var companyName = $('#company_name').val()
    var bodyHtml = '<div class="newInvoicePopupDetails"><span>Company:</span> <span>'+ companyName +'</span>';
    bodyHtml += '<span>Payment Gateway:</span> <span>'+ paymentGateways[selectedPaymentGateway] +'</span>';
    bodyHtml += '<span>Recurring:</span> <span>'+$("input[name='recurring_type']:checked").val().toUpperCase()+'</span>';
    bodyHtml += '<span>Invoice Date:</span> <span>'+$('#invoice_date').val()+' '+$('#invoice_time').val()+'</span>';
    if (recurringType == 'true'){
      bodyHtml += '<span>Billing Period:</span> <span>'+$('#term_start').val()+' to '+$('#term_end').val()+'</span>';
    }
    if ($('#gateway_order_no').val() != '') {
      bodyHtml += '<span>'+gatewayReferenceText+':</span> <span>'+$('#gateway_order_no').val()+'</span>';
    }
    if ($('#gateway_invoice_no').val() != '') {
      bodyHtml += '<span>Gateway Invoice ID: </span><span>'+$('#gateway_invoice_no').val()+'</span>';
    }

    if ($('#wire_transfer_reference_id').val() != '') {
      bodyHtml += '<span>Wire Transfer Ref No: </span><span>'+$('#wire_transfer_reference_id').val()+'</span>';
    }
    bodyHtml += "</div>";

    var quote = "<table class='table table-bordered' width='50%' style='margin-top: 30px;'>";
    quote +='<thead>'+
              '<th class="align-left" align="left" width="65%">Name</th>'+
              '<th class="align-center" align="center">Qty</th>'+
              '<th class="align-center" align="right">Price</th>'+
            '</thead>';

    $(".itemListing").each(function(){
      var name = $(this).children().find(".lineItemName").val();
      var quantity  = $(this).children().find(".lineItemQty").val();
      var amount  = $(this).children().find(".lineItemAmount").val();

      quote += '<tr>'+
                  '<td align="left">'+ name + '</td>'+
                  '<td class="align-center" align="center">'+ quantity + '</td>'+
                  '<td class="align-right" align="right">'+ amount + '</td>'+
               '</tr>';
    });

    var invoiceSubTotal = $("#sub_total_html").html();
    var invoiceTax = $("#invoice_tax").val();
    var invoiceTotal = $("#total_amount_html").html();


    quote += '<tr>'+
                '<td align="right" colspan="2"  class="align-right">Sub Total:</td>'+
                '<td align="right" class="align-right">' + selectedInvoiceCurrency + invoiceSubTotal + '</td>'+
              '</tr><tr>'+
                '<td align="right" colspan="2"  class="align-right">Tax:</td>'+
                '<td align="right" class="align-right">' + selectedInvoiceCurrency + invoiceTax + '</td>'+
              '</tr><tr>'+
                '<td align="right" colspan="2"  class="align-right"><strong>Total:</strong></td>'+
                '<td align="right" class="align-right"><strong>' + selectedInvoiceCurrency + invoiceTotal + '</strong></td>'+
              '</tr>';

    quote += "</table>";

    bodyHtml = bodyHtml + quote;

    $(".invoicePreviewBody").html(bodyHtml);
  };
});
