import Swal from 'sweetalert2';

$(document).ready(function(){
  function recomputeTotal(){
    var totalItemAmount = 0;
    $(".oneTimePaymentBody").find(".row").each(function() {
      if ($(this).find(".otpQtyRow").find('input').hasClass("qty")) {
        var itemQty = $.trim($(this).find(".otpQtyRow").find('input.qty').val());
        if (itemQty < 0) {
          itemQty = 0
          $(this).find(".otpQtyRow").find('input.qty').val(itemQty)
        }
      }
      if ($(this).find(".otpPriceRow").find('input').hasClass("otpPrice")) {
        var itemAmount = $.trim($(this).find(".otpPriceRow").find('input.otpPrice').val());
        if (itemAmount < 0) {
          itemAmount = 0
          $(this).find(".otpPriceRow").find('input.otpPrice').val(itemAmount)
        }
      }
      var qtyWithAmount = 0;
      if (itemQty && itemAmount) qtyWithAmount = itemQty * itemAmount;
      totalItemAmount = parseFloat(totalItemAmount) + parseFloat(qtyWithAmount);
    });
    $(".totalDisplay").html(totalItemAmount.toFixed(2));
  }

  $(document).on("change",".qty", function(){
    recomputeTotal();
  });

  $(document).on("change",".otpPrice", function(){
    $(this).val(Number($(this).val()).toFixed(2));
    recomputeTotal();
  });

  $(document).on("click",".deleteThisItem", function(){
    $(this).parent().parent().remove();
    recomputeTotal();
  });

  $("#oneTimePaymentForm").on("submit", function(){
    $(".customer-page-spinner").show();
  })

  App.showOtpSuccessModal = function() {
    $("#oneTimePaymentSendToModal").hide();
    $(".customer-page-spinner").hide();
    Swal.fire(
      'Success!',
      'One Time Payment request has been sent',
      'success'
    ).then(function () {
      location.reload()
    });
  }

  App.showOtpFailedModal = function() {
    $("#oneTimePaymentSendToModal").hide();
    $(".customer-page-spinner").hide();
    Swal.fire(
      'Failed!',
      'Please try again later',
      'error'
    ).then(function () {
      location.reload()
    });
  }

  function checkOtpFormElementsValid () {
    var fieldsVerified = true;
    $(".oneTimePaymentBody").children().each(function() {
      var nameField = $(this).children().find(".item")[0];
      var qtyField = $(this).children().find(".qty")[0];
      var amountField = $(this).children().find(".otpPrice")[0];
      if (!nameField.value || !qtyField.value || !amountField.value) {
        Swal.fire(
          '',
          'Please fill up all the fields!',
          'warning'
        )
        fieldsVerified = false;
        return false;
      }

      if (qtyField.value < 1) {
        Swal.fire(
          '',
          'Quantity must be greater than or equal to 1.',
          'warning'
        )
        fieldsVerified = false;
        return false;
      }

      if (amountField.value < 1) {
        Swal.fire(
          '',
          'Amount must be greater than or equal to 1.',
          'warning'
        )
        fieldsVerified = false;
        return false;
      }
    });

    return fieldsVerified;
  }

  $(document).on("click",".submitOtpBtn", function() {
    var fieldsVerified = checkOtpFormElementsValid();

    if (!fieldsVerified) {
      return false;
    }

    var currencyPrefix = $("#otp_currency_prefix").val();

    $("#oneTimePaymentModal").modal("hide");

    if ($("#payment_gateway").val() == '1') {
      $("#otp-gateway").html('Stripe');
    } else if ($("#payment_gateway").val() == '2') {
      $("#otp-gateway").html('2Checkout');
    } else if ($("#payment_gateway").val() == '3') {
      $("#otp-gateway").html('2Checkout Singapore');
    } else if ($("#payment_gateway").val() == '4') {
      $("#otp-gateway").html('Stripe UAE - USD');
    } else if ($("#payment_gateway").val() == '5') {
      $("#otp-gateway").html('Stripe UAE - AED');
    } else if ($("#payment_gateway").val() == '6') {
      $("#otp-gateway").html('Magnati - AED');
    } else {
      $("#otp-gateway").html('N/A');
    }

    var otpItems = "<table style='margin-top: 30px;' class='table'>";
    $(".oneTimePaymentBody").children().each(function() {
      var otpName = $(this).children().find(".item").val();
      var otpQty  = $(this).children().find(".qty").val();
      var otpAmount  = $(this).children().find(".otpPrice").val();

      otpItems += '<tr>'+
                  '<td align="left">'+ otpQty + 'x</td>'+
                  '<td align="left">'+ otpName + '</td>'+
                  '<td align="left">'+ currencyPrefix + otpAmount + '</td>'+
                  '</tr>';
    });


    otpItems += '<tr>'+
            '<td align="right"><strong>TOTAL:</strong></td>'+
            '<td align="right">&nbsp;</td>'+
            '<td align="right"><strong>'+ currencyPrefix + $(".totalDisplay").html() + '</strong></td>'+
            '</tr></table';

    $("#otp-table").html(otpItems);
    $("#oneTimePaymentSendToModal").modal();
  });
});
