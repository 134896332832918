// JS functions related to checkout page
import Swal from 'sweetalert2';

$(document).ready(function(){

  $("#payment-form, #payment-form-otp").on("submit", function(e){
    $("#checkoutSubmitBtn").attr('disabled','disabled');
  });

  $("#frmConfirmUpgrade").on("submit", function(e){
    $(".activationInProgress").show();
    $(".btnConfirmUpgrade").attr("disabled", 'disabled')
  });

  $(document).on("click",".btnStripeChangeDefaultCard, .btnStripeDeleteCard", function(e) {
    e.preventDefault();
    var url = $(this).attr("href");
    Swal.fire({
        html: 'Are you sure?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#4CAF50',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
    }).then(function (result) {
      if (result.value) {
        $(".customer-page-spinner").show();
        $.ajax({
          url: url,
          complete: function(data) {
            $(".customer-page-spinner").hide();
            $(window).attr("location", location.href);
          }
        });
      } else {
        return false;
      }
    })
  });
});

App.createStripeElements = function() {
  // Creates Stripe Card Elements and Event Listeners

  var elements = stripe.elements();
  var style = {
    base: {
      color: '#32325d',
      lineHeight: '24px',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  // Create an instance of the card Element
  var card = elements.create('card', {style: style});

  // Add an instance of the card Element into the `card-element` <div>
  card.mount('#card-element');

  // Handle real-time validation errors from the card Element.
  card.addEventListener('change', function(event) {
    var displayError = document.getElementById('card-errors');
    if (displayError) {
      if (event.error) {
        $('#card-errors').removeClass('hidden');
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
        $('#card-errors').addClass('hidden');
      }
    }
  });

  // Handle form submission
  var form = document.getElementById('payment-form');
  form.addEventListener('submit', function(event) {
    event.preventDefault();

    stripe.createToken(card).then(function(result) {
      if (result.error) {
        // Inform the user if there was an error
        $('#card-errors').removeClass('hidden');
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
      } else {
        $('#card-errors').addClass('hidden');
        // Send the token to your server
        stripeTokenHandler(result.token);
      }
    });
  });

  function stripeTokenHandler(token) {
    // Insert the token ID into the form so it gets submitted to the server
    var form = document.getElementById('payment-form');
    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripeToken');
    hiddenInput.setAttribute('value', token.id);
    form.appendChild(hiddenInput);
    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'newCard');
    hiddenInput.setAttribute('value', 'true');
    form.appendChild(hiddenInput);

    // Submit the form
    form.submit();
  }
}
