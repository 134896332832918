App.buildChart = function(element, details) {
  let color = randomColorGenerator(details.data.length);
  let chart = new Chart(element, {
    type: 'bar',
    data: {
      labels:  details.labels,
      datasets: [{
        label: '# of Count',
        data: details.data,
        backgroundColor: color[0],
        borderColor: color[1],
        borderWidth: 1
      }]
    },
    options: {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero:true
          }
        }]
      }
    }
  });
  return chart;
};

function randomColorGenerator(count){
  let colors = ['rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)']

  let borders = ['rgba(255,99,132,1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)']

  let randomColors =[], randomBorders = [];

  for(let i = 0; i < count; i++){
    randomColors.push(colors[i%5])
    randomBorders.push(borders[i%5])
  }
  return [randomColors, randomBorders];
};
