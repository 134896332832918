const API_CALL_LIMIT = 3
const START_COUNT = 1
const TIMEOUT_INTERVAL = 3000
App.fetchSummary = function(interval){
  summaryDetailsGet("get", "/dashboard/summary", interval, START_COUNT)
};

App.switchFilter = function(interval, element){
  $(".dashboard-filter").removeClass("active");
  element.classList.add("active");
  $("#dashboard-date-range").html(timeRange(interval))
  App.fetchSummary(interval);
  App.graphDetailsGet('/dashboard/activations', {interval}, START_COUNT, "activation-graph");
  App.graphDetailsGet('/dashboard/recurring', {interval}, START_COUNT, "recurring-graph");
  App.graphDetailsGet('/dashboard/non_recurring', {interval}, START_COUNT, "non-recurring-graph");
  App.graphDetailsGet('/dashboard/refund', {interval}, START_COUNT, "refund-graph");
}

App.graphDetailsGet = function(path, data, counter, canvasID){
  let element = document.getElementById(canvasID).getContext('2d');
  $.ajax({
    type: "get",
    url: path,
    data: data,
    success: function(response){
      if (response.data.status == 'waiting' && counter <= API_CALL_LIMIT) {
        $("." + canvasID + "-spinner").show();
        setTimeout(function() {
          App.graphDetailsGet(path, data, ++counter, canvasID);
        }, 3000);
      } else if (counter > API_CALL_LIMIT) {
        generateChart(canvasID, undefined)
      } else {
        generateChart(canvasID, response.data[0])
      }
    }
  });
}

function summaryDetailsGet(type, url, interval, counter) {
  $.ajax({
    type: "get",
    url: "/dashboard/summary",
    data: { interval },
    success: function(response){
      if (response.data.status == 'waiting' && counter <= API_CALL_LIMIT) {
        $(".summary-spinner").show();
        $(".summary_data").hide();
        setTimeout(function() {
          summaryDetailsGet(type, url, interval, ++counter)
        }, TIMEOUT_INTERVAL);
      } else if (counter > API_CALL_LIMIT) {
        $(".summary-spinner").hide();
        $(".summary_data").show();
        $('.recurring-cost').html('0.00')
        $('.non-recurring-cost').html('0.00')
        $('.refund-cost').html('0.00')
        $('.activation-count').html('0')
      } else {
        $(".summary-spinner").hide();
        $(".summary_data").show();
        $('.recurring-cost').html(replaceNumberWithCommas(response.data.recurring))
        $('.non-recurring-cost').html(replaceNumberWithCommas(response.data.non_recurring))
        $('.refund-cost').html(replaceNumberWithCommas(response.data.refund))
        $('.activation-count').html(replaceNumberWithCommas(response.data.activations))
      }
    }
  });
}

function replaceNumberWithCommas(number) {
  let value= number.toString().split(".");
  value[0] = value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return value.join(".");
};

function generateChart(canvasID, data) {
  $("." + canvasID + "-spinner").hide();
  $("#no-data-" + canvasID).hide();
  document.getElementById(canvasID).remove();
  let newCanvas = $('<canvas/>',{'id':canvasID})
  $("#" + canvasID + "-container").append(newCanvas)
  if (data == undefined) {
    $("#no-data-"+ canvasID).show();
    return
  }
  let canvas = document.getElementById(canvasID).getContext('2d');
  var details = {
    data: Object.values(data),
    labels: Object.keys(data)
  }
  App.buildChart(canvas, details);
}

function timeRange(interval) {
  let date = new Date();
  let options = {year: 'numeric', month: 'long', day: 'numeric' };
  if (interval == 'today') return date.toLocaleDateString('en-US', options)

  dates = summaryDates(interval, date)
  startDate = dates.startDate
  endDate = dates.endDate
  return formatDateRange(startDate, endDate, options)
}

function summaryDates(interval, date) {
  if (interval == 'this_month') return thisMonthStartAndEndDate(date)
  if (interval == 'last_month') return lastMonthStartAndEndDate(date)
  if (interval == '3_months') return MonthDates(date, 3)
  if (interval == '6_months') return MonthDates(date, 6)
  if (interval == 'year') return MonthDates(date, 12)
}

function thisMonthStartAndEndDate(date) {
  let startDate, endDate
  startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  endDate = date
  return {startDate, endDate}
}

function lastMonthStartAndEndDate(date) {
  let startDate, endDate
  startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  endDate = new Date(date.getFullYear(), date.getMonth(), 0);
  return {startDate, endDate}
}

function MonthDates(date, difference) {
  let startDate, endDate
  startDate = new Date(date.getFullYear(), date.getMonth() - difference, date.getDate());
  endDate = date
  return {startDate, endDate}
}

function formatDateRange(startDate, endDate, options) {
  return formatDate(startDate, options).concat(
    ' - ', formatDate(endDate, options)
  )
}

function formatDate(date, options) {
  return date.toLocaleDateString('en-US', options)
}
