import Swal from 'sweetalert2';

$(document).ready(function(){
  $(document).on("click",".InvoiceRefund", function(){
    Swal.fire({
      title: 'Refund Amount',
      input: 'number',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Refund',
      inputPlaceholder: 'Refund Amount',
      inputValidator: (value) => {
        if (!value || parseFloat(value) <= 0) {
          return 'Please enter a valid refund amount'
        }
      }
    }).then((result) => {
      if (result.value) {
        let invoiceAmount = $('#invoice_amount').val();
        if (parseFloat(result.value) <= parseFloat(invoiceAmount)) {
          $.ajax({
            type: "post",
            url: "/invoices/refunds",
            data: {
              invoice_id : $("#invoice_id").val(),
              refund_amount: result.value
            },
            success: function(data){
              Swal.fire(
                'Success!',
                'Amount has been refunded',
                'success'
              ).then(function () {
                location.reload()
              });
            },
            error: function(error) {
              Swal.fire(
                'Sorry!',
                'Amount has not been refunded. Please try again.',
                'error'
              )
            }
          })
        } else {
          Swal.fire(
            'Oops!',
            'Refund amount is greater than the invoice value. Please try again.',
            'error'
          );
        }
      }
    });
  });

  $(document).on("click",".invoiceMail", function(){
    var container = $("#sendInvoiceModalSection");
    var invoiceId = $("#invoice_id").val();
    var invoiceDate = $("#invoice_date").val();
    var customerName = $("#customer_name").val();
    var customerEmail = $("#customer_email").val();
    $("#send_invoice_id").val(invoiceId);
    $("#send_to").val(customerEmail);
    $("#subject").val('Sapaad Invoice - ' + customerName + '- ' + invoiceDate);
    $("#sendInvoiceModal").modal();
  });

  $(document).on("click",".sendInvoicePopupBtn", function() {
    var container = $("#sendInvoiceModalSection");
    var customerName = $(this).attr("data-name");
    var customerEmail = $(this).attr("data-email");
    var hasInvoice = $(this).attr("data-invoice") == "true";
    var invoiceId = $(this).attr("data-invoice-id");
    var invoiceDate = $(this).attr("data-date");
    if (hasInvoice) {
      $("#send_invoice_id").val(invoiceId);
      $("#send_to").val(customerEmail);
      $("#subject").val('Sapaad Invoice - ' + customerName + '- ' + invoiceDate);
      $("#sendInvoiceModal").modal();
    } else {
      Swal.fire(
        'Oops!',
        'Customer has no invoice',
        'error'
      )
    }
  });

  $(document).on("click",".sendInvoiceBtn", function() {
    $(".customer-page-spinner").show();

    var sendToEmail = $("#send_to").val();
    if (!sendToEmail) {
      Swal.fire(
        '',
        'Please enter email.',
        'warning'
      )
      $(".customer-page-spinner").hide();

      return false;
    }

    $.ajax({
      type: "post",
      url: "/invoices/customer_emails",
      data: {
        invoice_id: $("#send_invoice_id").val(),
        send_to: sendToEmail
      },
      success: function(data){
        Swal.fire(
          'Success!',
          'Invoice sent!',
          'success'
        )
      },
      error: function(error) {
        Swal.fire(
          'Oops!',
          'Failed to send invoice. Please try again.',
          'error'
        )
      },
      complete: function() {
        $(".customer-page-spinner").hide();
        $("#sendInvoiceModal").modal('hide');
      }
    });
  });

  App.showManualInvoiceGenerationSuccessModal = function() {
    $("#createInvoice").modal("hide");
    $(".customer-page-spinner").hide();
    Swal.fire(
      'Success!',
      'Invoice has been successfully sent',
      'success'
    ).then(function () {
      location.reload()
    });
  }

  App.showManualInvoiceGenerationpFailedModal = function() {
    $(".customer-page-spinner").hide();
    Swal.fire(
      'Duplicate Gateway IDs Detected',
      "The Gateway Invoice ID and Order ID you've entered are already in use.",
      'error'
    );
  }
});
