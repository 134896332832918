import Swal from 'sweetalert2';
window.App = {};

$(document).ready(function(){
  $(document).on("click",".expand", function(){
    $(".excess").addClass("expanded");
  });

  App.print_invoice_to_customer = function print_invoice_to_customer(customerID) {
    $.ajax({
      type: "post",
      url: "/invoices/prints",
      data: { id : customerID }
    })
  }

  App.remove_plan_success = function remove_plan_success() {
    Swal.fire(
      'Success!',
      'Selected Plan has been unsubscribed.',
      'success'
    )
  }

  App.remove_plan_failure = function remove_plan_failure() {
    Swal.fire(
      'Failed!',
      'Unable to remove selected plan. Please try again',
      'failure'
    )
  }
})
