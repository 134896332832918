import Swal from 'sweetalert2';
const PRICING_VERSION_1 = 1;
const PRICING_VERSION_2 = 2;
const PRICING_VERSION_3 = 3;
const MAX_DATE_RANGE_SELECTION_IN_DAYS = 7;

$(document).on("click",".removePlanBtn", function(){
  var planId = $(this).attr("data-id");
  var planName = $(this).attr("data-name");

  Swal.fire({
    title: 'Do you want to remove ' + planName + '?',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Confirm'
  }).then((result) => {
    if (result.value) {
      $(".customer-page-spinner").show();
      $.ajax({
        type: "post",
        url: "/subscriptions/remove_plans",
          data: {
            plan_id: planId,
            payment_gateway_id: $("#payment_gateway_id").val()
          },
          success: function(data) {
            $(".customer-page-spinner").hide();
            Swal.fire(
              'Success!',
              'Selected Plan has been unsubscribed.',
              'success'
            ).then(function () {
              window.location.reload()
            });
          },
          error: function(error) {
            $(".customer-page-spinner").hide();
            Swal.fire(
              'Failed!',
              'Unable to remove selected plan. Please try again',
              'error'
            )
          }
      })
    }
  });
});

$(document).on("click","#updatePlanBtn", function(){
  $(".customer-page-spinner").show();

  var walkin_plan = $('#walkin_plan').val();
  var callcenter_plan = $('#callcenter_plan').val();
  var online_plan = $('#online_plan').val();
  var support_plan = $('#support_plan').val();
  var inventory_plan = $('#inventory_plan').val();
  if (inventory_plan) {
    inventory_plan = inventory_plan + '_' + walkin_plan;
  }
  var sms_plan = $('#sms_plan').val();
  var loyalty_plan = $('#loyalty_plan').val();
  var kds_count = $('#kds_count').val();

  var customer_id = $('#customer_id').val();
  var sapaad_company_id = $('#sapaad_company_id').val();

  $.ajax({
    type: "post",
    url: "/subscriptions/change_plans",
    data: {
      plans: [
        { category: 'base', plan: walkin_plan },
        { category: 'cc', plan: callcenter_plan },
        { category: 'online', plan: online_plan },
        { category: 'support', plan: support_plan },
        { category: 'inventory', plan: inventory_plan },
        { category: 'sms', plan: sms_plan },
        { category: 'loyalty', plan: loyalty_plan },
        { category: 'kds', plan: 'kds', quantity: kds_count }
      ],
      pricing_version: PRICING_VERSION_1,
      customer_id: customer_id
    },
    success: function(data) {
      $(".customer-page-spinner").hide();
      if (data.status) {
        Swal.fire(
          'Success!',
          'Selected Plan has been changed',
          'success'
        ).then(function () {
          window.location.reload()
        });
      } else {
        Swal.fire(
          'Failed!',
          'Selected Plan cannot be changed. Please try again later',
          'error'
        )
      }
    }
  })
});

$(document).on("click", "#updatePlanBtnV2", function(){
  $(".customer-page-spinner").show();

  var outlets = $('#outlets').val();
  var crm_plan = $('#crm_plan_v2').val();
  var sms_plan = $('#sms_plan_v2').val();
  var online_plan = $('#online_v2').val();
  var connect_plan = $('#connect_v2').val();
  var sapaad_direct_plan = $('#sapaad_direct').val();
  var inventory_plan = $('#inventory_v2').val();
  var support_plan = $('#support_v2').val();
  var loyalty_plan = $('#loyalty_v2').val();
  var kds_plan = $('#kds_v2').val();
  var foodpanda_plan = $('#foodpanda').val();

  var annual = $('#annual').prop('checked');
  var subscription_period = $('input[name="billingCycle"]:checked').val();

  var sapaad_company_id = $('#sapaad_company_id').val();
  var customer_id = $('#customer_id').val();
  var payment_gateway_id = $('#new_payment_gateway').val();
  var override_payment_gateway = $('#override_payment_gateway').prop('checked');
  var pricing_version = $(this).data("pricingVersion")


  $.ajax({
    type: "post",
    url: "/subscriptions/change_plans",
    data: {
      outlets: outlets,
      customer_id: customer_id,
      sapaad_company_id: sapaad_company_id,
      pricing_version: pricing_version,
      annual: annual,
      subscription_period: subscription_period,
      payment_gateway_id: payment_gateway_id,
      override_payment_gateway: override_payment_gateway,
      plans: [
        { category: 'base', outlets: outlets },
        { category: 'cc', plan_id: crm_plan },
        { category: 'sms', plan_id: sms_plan },
        { category: 'online', plan_id: online_plan },
        { category: 'connect', plan_id: connect_plan },
        { category: 'support', plan_id: support_plan },
        { category: 'inventory', plan_id: inventory_plan },
        { category: 'loyalty', plan_id: loyalty_plan },
        { category: 'kds', plan_id: kds_plan },
        { category: 'sapaad_direct', plan_id: sapaad_direct_plan },
        { category: 'foodpanda', plan_id: foodpanda_plan }
      ]
    },
    success: function(data) {
      $(".customer-page-spinner").hide();
      if (data.status) {
        Swal.fire(
          'Success!',
          'Selected Plan has been changed',
          'success'
        ).then(function () {
          window.location.reload()
        });
      } else {
        Swal.fire(
          'Failed!',
          'Selected Plan cannot be changed. Please try again later',
          'error'
        )
      }
    }
  })
});

$(document).on("click", "#updatePlanBtnV3", function(){
  $(".customer-page-spinner").show();

  var outlets = $('#outlets_v3').val();
  var crm_plan = $('#crm_plan_v3').val();
  var sms_plan = $('#sms_plan_v3').val();
  var online_plan = $('#online_v3').val();
  var connect_plan = $('#connect_v3').val();
  var sapaad_direct_plan = $('#sapaad_direct_v3').val();
  var inventory_plan = $('#inventory_v3').val();
  var support_plan = $('#support_v3').val();
  var loyalty_plan = $('#loyalty_v3').val();
  var kds_plan = $('#kds_v3').val();
  var foodpanda_plan = $('#foodpanda_v3').val();
  var kiosk_plan = $('#kiosk_v3').val();

  var annual = $('#annual').prop('checked');
  var subscription_period = $('input[name="billingCycle"]:checked').val();

  var sapaad_company_id = $('#sapaad_company_id').val();
  var customer_id = $('#customer_id').val();
  var payment_gateway_id = $('#new_payment_gateway_v3').val();
  var override_payment_gateway = $('#override_payment_gateway_v3').prop('checked');
  var pricing_version = $(this).data("pricingVersion");

  var sapaad_dash_plan = $('#sapaad_dash').val();
  var sapaad_waiter_plan = $('#sapaad_waiter').val();
  var urban_piper_plan = $('#urban_piper').val();
  var otter_plan = $('#otter').val();
  var sapaadVantagePlan = $('#sapaad_vantage').val();
  var svExtendedAnalyticsPlan, svAdvancedWidgetsPlan, svInventoryPlan, svDeepCompPlan = '';
  if(sapaadVantagePlan) {
    var extAnalyticsId = $('#sv_extended_analytics');
    svExtendedAnalyticsPlan = extAnalyticsId.prop('checked') ? extAnalyticsId.val() : '';
    var advWidgetId = $('#sv_advanced_widgets');
    svAdvancedWidgetsPlan = advWidgetId.prop('checked') ? advWidgetId.val() : '';
    var invId = $('#sv_inventory');
    svInventoryPlan = invId.prop('checked') ? invId.val() : '';
    var svDeepCompId = $('#sv_deep_comparison_analysis');
    svDeepCompPlan = svDeepCompId.prop('checked') ? svDeepCompId.val() : '';
  }

  $.ajax({
    type: "post",
    url: "/subscriptions/change_plans",
    data: {
      outlets: outlets,
      customer_id: customer_id,
      sapaad_company_id: sapaad_company_id,
      pricing_version: pricing_version,
      annual: annual,
      subscription_period: subscription_period,
      payment_gateway_id: payment_gateway_id,
      override_payment_gateway: override_payment_gateway,
      plans: [
        { category: 'base', outlets: outlets },
        { category: 'cc', plan_id: crm_plan },
        { category: 'sms', plan_id: sms_plan },
        { category: 'online', plan_id: online_plan },
        { category: 'connect', plan_id: connect_plan },
        { category: 'support', plan_id: support_plan },
        { category: 'inventory', plan_id: inventory_plan },
        { category: 'loyalty', plan_id: loyalty_plan },
        { category: 'kds', plan_id: kds_plan },
        { category: 'sapaad_direct', plan_id: sapaad_direct_plan },
        { category: 'foodpanda', plan_id: foodpanda_plan },
        { category: 'kiosk', plan_id: kiosk_plan },
        { category: 'sapaad_dash', plan_id: sapaad_dash_plan },
        { category: 'sapaad_waiter', plan_id: sapaad_waiter_plan },
        { category: 'urban_piper', plan_id: urban_piper_plan },
        { category: 'otter', plan_id: otter_plan },
        { category: 'sapaad_vantage', plan_id: sapaadVantagePlan },
        { category: 'sv_extended_analytics', plan_id: svExtendedAnalyticsPlan },
        { category: 'sv_advanced_widgets', plan_id: svAdvancedWidgetsPlan },
        { category: 'sv_inventory', plan_id: svInventoryPlan },
        { category: 'sv_deep_comparison_analysis', plan_id: svDeepCompPlan },
      ]
    },
    success: function(data) {
      $(".customer-page-spinner").hide();
      if (data.status) {
        Swal.fire(
          'Success!',
          'Selected Plan has been changed',
          'success'
        ).then(function () {
          window.location.reload()
        });
      } else {
        Swal.fire(
          'Failed!',
          'Selected Plan cannot be changed. Please try again later',
          'error'
        )
      }
    }
  })
});

$(document).on("click", "#updatePlanBtnV4", function(){
  var sapaad_company_id = $('#sapaad_company_id').val();
  var customer_id = $('#customer_id').val();
  var per_transaction_fee = $('#per_transaction_fee').val();
  var payment_gateway_id = $('#new_payment_gateway_band').val();
  var override_payment_gateway = $('#override_payment_gateway_band').prop('checked');
  var pricing_version = $(this).data("pricingVersion")
  var subscription_period = $('#billing_cycle_band').val();
  var band_rate = $('#band_rate').val();
  var band = $('input[name="bandVersion"]:checked').val();
  var bandFieldPostfix = band == 'b4' ? '_b4' : '_free_band';

  var outlets = $('#outlets'+bandFieldPostfix).val();
  var crm_plan = $('#crm_plan'+bandFieldPostfix).val();
  var sms_plan = $('#sms_plan'+bandFieldPostfix).val();
  var online_plan = $('#online'+bandFieldPostfix).val();
  var connect_plan = $('#connect'+bandFieldPostfix).val();
  var sapaad_direct_plan = $('#sapaad_direct'+bandFieldPostfix).val();
  var inventory_plan = $('#inventory'+bandFieldPostfix).val();
  var support_plan = $('#support'+bandFieldPostfix).val();
  var loyalty_plan = $('#loyalty'+bandFieldPostfix).val();
  var kds_plan = $('#kds'+bandFieldPostfix).val();
  var foodpanda_plan = $('#foodpanda'+bandFieldPostfix).val();
  var kiosk_plan = $('#kiosk'+bandFieldPostfix).val();

  var sapaad_dash_plan = $('#sapaad_dash'+bandFieldPostfix).val();
  var sapaad_waiter_plan = $('#sapaad_waiter'+bandFieldPostfix).val();
  var urban_piper_plan = $('#urban_piper'+bandFieldPostfix).val();
  var otter_plan = $('#otter'+bandFieldPostfix).val();

  var sapaadVantagePlan = $('#sapaad_vantage'+bandFieldPostfix).val();
  var svExtendedAnalyticsPlan, svAdvancedWidgetsPlan, svInventoryPlan, svDeepCompPlan = '';
  if(sapaadVantagePlan) {
    var extAnalyticsId = $('#sv_extended_analytics'+bandFieldPostfix);
    svExtendedAnalyticsPlan = extAnalyticsId.prop('checked') ? extAnalyticsId.val() : '';
    var advWidgetId = $('#sv_advanced_widgets'+bandFieldPostfix);
    svAdvancedWidgetsPlan = advWidgetId.prop('checked') ? advWidgetId.val() : '';
    var invId = $('#sv_inventory'+bandFieldPostfix);
    svInventoryPlan = invId.prop('checked') ? invId.val() : '';
    var svDeepCompId = $('#sv_deep_comparison_analysis'+bandFieldPostfix);
    svDeepCompPlan = svDeepCompId.prop('checked') ? svDeepCompId.val() : '';
  }

  if (per_transaction_fee == '' || band_rate == '') {
    Swal.fire(
      'Failed!',
      'Please provide all required information',
      'error'
    )
    return false;
  } else {
    $(".customer-page-spinner").show();
  }

  $.ajax({
    type: "post",
    url: "/subscriptions/change_plans",
    data: {
      outlets: outlets,
      customer_id: customer_id,
      sapaad_company_id: sapaad_company_id,
      pricing_version: pricing_version,
      subscription_period: subscription_period,
      band: band,
      band_rate: band_rate,
      per_transaction_fee: per_transaction_fee,
      payment_gateway_id: payment_gateway_id,
      override_payment_gateway: override_payment_gateway,
      plans: [
        { category: 'base', outlets: outlets },
        { category: 'cc', plan_id: crm_plan },
        { category: 'sms', plan_id: sms_plan },
        { category: 'online', plan_id: online_plan },
        { category: 'connect', plan_id: connect_plan },
        { category: 'support', plan_id: support_plan },
        { category: 'inventory', plan_id: inventory_plan },
        { category: 'loyalty', plan_id: loyalty_plan },
        { category: 'kds', plan_id: kds_plan },
        { category: 'sapaad_direct', plan_id: sapaad_direct_plan },
        { category: 'foodpanda', plan_id: foodpanda_plan },
        { category: 'kiosk', plan_id: kiosk_plan },
        { category: 'sapaad_dash', plan_id: sapaad_dash_plan },
        { category: 'sapaad_waiter', plan_id: sapaad_waiter_plan },
        { category: 'urban_piper', plan_id: urban_piper_plan },
        { category: 'otter', plan_id: otter_plan },
        { category: 'sapaad_vantage', plan_id: sapaadVantagePlan },
        { category: 'sv_extended_analytics', plan_id: svExtendedAnalyticsPlan },
        { category: 'sv_advanced_widgets', plan_id: svAdvancedWidgetsPlan },
        { category: 'sv_inventory', plan_id: svInventoryPlan },
        { category: 'sv_deep_comparison_analysis', plan_id: svDeepCompPlan }
      ]
    },
    success: function(data) {
      $(".customer-page-spinner").hide();
      if (data.status) {
        Swal.fire(
          'Success!',
          'Selected Plan has been changed',
          'success'
        ).then(function () {
          window.location.reload()
        });
      } else {
        Swal.fire(
          'Failed!',
          'Selected Plan cannot be changed. Please try again later',
          'error'
        )
      }
    }
  })
});

$(document).on("click",".btnUnsubscribeNow", function(){
  var pricingVersion = $(this).data("pricingVersion");
  var cancellationURL = '/api/v'+pricingVersion+'/subscriptions/cancellations'

  Swal.fire({
    title: 'Are you sure?',
    text: "This action cannot be undone.",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'No Thanks',
    confirmButtonText: 'Cancel Subscription'
  }).then((result) => {
    if (result.value) {
      $(".customer-page-spinner").show();
      $.ajax({
        type: "post",
        url: cancellationURL,
        data: {
          subscription_id: $("#subscription_id").val(),
          payment_gateway_id: $("#payment_gateway_id").val(),
          type: 'cancel_now'
        },
        success: function(data) {
          $(".customer-page-spinner").hide();
          if (data.status) {
            Swal.fire(
              'Success!',
              'Subscription has been cancelled.',
              'success'
            ).then(function () {
              window.location.reload()
            });
          } else {
            Swal.fire(
              'Failed!',
              'Please try again later',
              'error'
            )
          }
        }
      })
    }
  });
});

$(document).on("click",".btnUnsubscribe", function(){
  var pricingVersion = $(this).data("pricingVersion");
  var cancellationURL = '/api/v'+pricingVersion+'/subscriptions/cancellations'

  Swal.fire({
    title: 'Are you sure?',
    text: "This action cannot be undone.",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'No Thanks',
    confirmButtonText: 'Cancel on Next Renewal'
  }).then((result) => {
    if (result.value) {
      $(".customer-page-spinner").show();
      $.ajax({
        type: "post",
        url: cancellationURL,
        data: {
          subscription_id : $("#subscription_id").val(),
          payment_gateway_id: $("#payment_gateway_id").val(),
          type: 'cancel_on_next_renewal'
        },
        success: function(data) {
          $(".customer-page-spinner").hide();
          if (data.status) {
            Swal.fire(
              'Success!',
              'Subscription will be cancelled on next renewal.',
              'success'
            ).then(function () {
              window.location.reload()
            });
          } else {
            Swal.fire(
              'Failed!',
              'Please try again later',
              'error'
            )
          }
        }
      }
    )}
  });
});

$(document).on("click",".btnStripeConnectActions", function(){
  var action = $(this).data("action");
  var connectedAccountId = $(this).data("connected-account-id");
  var customerId = $('#customer_id').val();
  var actionUrl = '/api/v4/subscriptions/stripe_connect/'+action;
  var confirmButtonText, successTitle, successText;
  var postData = { connected_account_id : connectedAccountId, customer_id: customerId }

  var swalHash = {
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Go back'
  }

  switch(action) {
    case "reject_account":
      successTitle = 'Account Rejected'
      successText = 'This account is now disabled for payments and payouts.';
      var rejectSwalHash =  {
        title: 'Reject Account?',
        html: "Only proceed to reject this account if you believe it is committing "+
        "<a href='https://stripe.com/docs/connect/best-practices#fraud' target='_blank'>fraud</a> or violating the " +
        "<a href='https://stripe.com/docs/connect/updating-accounts#tos-acceptance' target='_blank'>Stripe Services Agreement</a>." + 
        "<br /><br />Rejecting the account will disable payments and payouts on the account and improve Stripe’s fraud detection systems.",
        input: 'select',
        inputOptions: { 'fraud': 'Fraud', 'terms_of_service': 'Violates services agreement', 'other': 'Other' },
        inputPlaceholder: 'Select Reason',
        confirmButtonText: 'Reject Account',
        inputValidator: function (value) {
          return new Promise(function (resolve, reject) {
            if (value !== '') {
              resolve();
            } else {
              $('.swal2-select').css('border-color', 'red');
              resolve('Select a reason to proceed');
              $('.swal2-confirm').focus();
            }
          });
        }
      }
      swalHash = Object.assign(swalHash, rejectSwalHash);
      break;
    case "remove_account":
      successTitle = 'Account Removed'
      successText = 'This account has been removed.';
      swalHash = Object.assign(swalHash, { 
          title: 'Confirm Account Removal',
          html: 'Are you sure you want to remove this account? <br />This action cannot be undone.',
          confirmButtonText: 'Remove Account'
        }
      );
      break;
    default:
      //
  }
  Swal.fire(swalHash).then((result) => {
    if (result.value) {
      if (action == 'reject_account') {
        postData = Object.assign(postData, { rejected_reason: result.value } );
      }
      $(".customer-page-spinner").show();
      $.ajax({
        type: "post",
        url: actionUrl,
        data: postData,
        success: function(data) {
          $(".customer-page-spinner").hide();
          if (data.status) {
            Swal.fire(
              successTitle,
              successText,
              'success'
            ).then(function () {
              window.location.reload()
            });
          } else {
            Swal.fire(
              'Failed!',
              'Please try again later',
              'error'
            )
          }
        }
      }
    )}
  });
});

App.showActionFailedPopup = function() {
  Swal.fire(
    'Failed!',
    'Please try again later',
    'error'
  )
}

App.calculateTotalInvoiceAmount = function() {
  var totalInvoiceAmount = 0
  var totalInvoiceTax = 0

  $('.invoice-amount').each(function(){
    totalInvoiceAmount += parseFloat($(this).text());
  });
  $('.invoice-tax').each(function(){
    totalInvoiceTax += parseFloat($(this).text());
  });

  $('#total-amount').text(parseFloat(totalInvoiceAmount).toFixed(2));
  $('#total-tax').text(parseFloat(totalInvoiceTax).toFixed(2));
}

App.initiateInfoTip = function() {
  $('.infoTip').popover({ trigger: "hover" });
}

App.initiateAllowDecimal = function() {
  $(".allowDecimal").on("input", function(evt) {
    var self = $(this);
    self.val(self.val().replace(/[^0-9\.]/g, ''));
    if ((evt.which != 46 || self.val().indexOf('.') != -1) && (evt.which < 48 || evt.which > 57)) 
    {
     evt.preventDefault();
    }
  });
}
App.showGenerateSalesPayoutsReconciliationReportSuccessModal = function() {
  $(".customer-page-spinner").hide();
  Swal.fire(
    '',
    "We’re generating the Sales Report for Payouts Reconciliation. Once it’s ready, you will receive an email at subscriptions@sapaad.com with the link to download the report.",
    'info'
  ).then(function () {
    location.reload()
  });
}

App.showInvalidToDateSelection = function() {
  Swal.fire(
    'Failed!',
    "'To' date must be later than 'From' date.",
    'error'
  );
}

App.showInvalidDateRangeSelection = function() {
  Swal.fire(
    'Date Range Limit Exceeded',
    "Please choose a date range within "+MAX_DATE_RANGE_SELECTION_IN_DAYS+" days.",
    'error'
  );
}

function formatDateString(date) {
  var day = date.getDate();
  var month = date.getMonth();
  var year = date.getFullYear();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return day + '/' + (month+1) + '/' + year + ', ' + strTime;
}

App.confirmPayoutReportDateSelection = function() {
  var date1 = new Date(document.getElementById('start_date').value);
  var date2 = new Date(document.getElementById('end_date').value);
  const startDate = formatDateString(date1);
  const endDate = formatDateString(date2);
  Swal.fire({
    title: 'Generate Sales Report',
    html: "You are about to generate a Payouts Reconciliation Sales Report from <br />"+startDate+" to " +endDate+'.<br /><br />Would you like to proceed?',
    type: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'No',
    confirmButtonText: 'Yes'
  }).then((result) => {
    if (result.value) {
      reportConfirmation = true;
      $("#generateSalesReport").click();
    }
    return false;
  });
}
