import Swal from 'sweetalert2';

$(document).ready(function(){
  $(document).on('click',".rate_schedule_button", function(){

    $("#gateway_id").val($(this).attr("data-gateway-id"));
    $("#old_convertion_rate").val($(this).attr("data-old-convertion-rate"));
    $("#old_rate").html($(this).attr("data-old-convertion-rate"));
    $("#new_convertion_rate").val('');
    $("#scheduled_time").val('');
    $('.fake-btn-success').css('display','none');
    $("#scheduledRateModal").attr("data-parent",$(this).attr("data-class"));
    $(".btn-save").show();
  });

  $(document).on("submit",'#updateRateForm', function(){
    $(".btn-save").css('display','none');
    $('.fake-btn-success').show();
  });
})

App.showScheduledActionFailedPopup = function() {
  Swal.fire(
    'Failed!',
    'Something went wrong',
    'error'
  )
}
App.showScheduledActionSuccessPopup = function() {
  Swal.fire(
    'Success!',
    'Successfully scheduled',
    'success'
  )
}
