// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery/dist/jquery.min.js")
require("bootstrap/dist/js/bootstrap.bundle.min.js")
require("sweetalert2/dist/sweetalert2.all.min.js")
require("app.js")
require("../custom/checkout")
require("../custom/chart")
require("../custom/dashboard")
require("../custom/invoice")
require("../custom/customer")
require("../custom/one_time_payment")
require("../custom/subscriptions")
require("../custom/exchange_rates")
require("chart.js")
